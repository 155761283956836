import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import Divider from '../../components/ui/Divider/Divider';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import SVGIcons from '../../components/utils/SVGIcons';
import { Direction } from '../../globals';
import '../sobre/about.scss';

const AboutSolidity: React.FC = () => {

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="O que é Solidity?"
                url="sobre-solidity/"
                description="O que é Solidity?"
            />
            <PageBanner className="page-banner-single-item">
                <Fragment>
                    <h1>
                        O que é Solidity?
                    </h1>
                </Fragment>
                <Fragment>
                </Fragment>
            </PageBanner>

            <PageSection>
                <Container>
                    <Group size={1}>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <SVGIcons icon='solidity' width={64} height={64} />
                            <p>
                                Solidity é uma linguagem de alto nível orientada a objetos para implementação de contratos inteligentes. 
                                Contratos inteligentes são programas que regem o comportamento das contas no estado Ethereum.
                            </p>
                            <p>
                                Solidity é uma <a href='https://en.wikipedia.org/wiki/List_of_programming_languages_by_type#Curly-bracket_languages' target='_blank'>linguagem de colchetes</a> projetada para direcionar a Ethereum Virtual Machine (EVM). 
                                É influenciado por C++, Python e JavaScript. Você pode encontrar mais detalhes sobre quais idiomas o Solidity foi inspirado <a href='https://docs.soliditylang.org/en/v0.8.15/language-influences.html' target='_blank'>clicando aqui</a>.
                            </p>
                            <p>
                                O Solidity é tipado estaticamente, suporta herança, bibliotecas e tipos complexos definidos pelo usuário, entre outros recursos. 
                                Com o Solidity, você pode criar contratos para usos como votação, crowdfunding, leilões cegos e carteiras multi-assinatura.
                            </p>
                            <p>
                                Ao implantar contratos, você deve usar a versão mais recente do Solidity. 
                                Exceto em casos excepcionais, apenas a versão mais recente recebe <a href='https://github.com/ethereum/solidity/security/policy#supported-versions' target='_blank'>correções de segurança</a>. 
                                Além disso, alterações importantes, bem como novos recursos são introduzidos regularmente.
                            </p>
                            <p>
                                A melhor maneira de testar um script Solidity é utilizando a ferramenta <a href='https://remix.ethereum.org/' target='_blank'>Remix</a>.
                            </p>
                        </Block>
                    </Group>
                            
                    <Divider direction={Direction.horizontal} />

                    <Block
                        className="about-block"
                        direction={Direction.vertical}
                    >
                        <center><p>Links Oficiais do Solidity<br/>
                            <a href='https://soliditylang.org/' target='_blank'>Site Solidity</a> | <a href='https://docs.soliditylang.org/en/v0.8.15/' target='_blank'>Documentação</a> | <a href='https://github.com/ethereum/solidity' target='_blank'>Repositório no GitHub</a>
                        </p></center>
                    </Block>

                </Container>
            </PageSection>

        </PageLayout>
    );
};

export default AboutSolidity;
